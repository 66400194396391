@import '../../styles/mixins';

.sidebar-container {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;

  .ant-menu-item-icon {
    width: 48px;
  }

  &.sidebar-collapsed {
    .ant-menu-item {
      &-icon {
        + span {
          margin-left: unset;
        }
      }
    }

    .ant-menu-item-icon {
      width: unset;
    }
  }

  .anticon {
    + span {
      width: 100%;
    }
  }

  .ant-menu {
    transition: background 0.3s;

    .ant-menu-item,
    .ant-menu-submenu {
      margin-top: 15px;

      .ant-menu-submenu-arrow {
        opacity: 1 !important;
      }

      .anticon + span {
        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      .sidebar-link-container {
        align-items: center;
        display: flex;
      }
    }
  }

  .sidebar-content {
    border-radius: var(--border-radius-base);
    height: fit-content;
    margin: 20px;
    min-height: calc(100vh - 40px);
    position: relative;
    width: fit-content;

    .mobile-tabs {
      width: 100%;

      .ant-tabs-tab {
        justify-content: center;
        width: 25%;

        .anticon {
          color: var(--color__gray_lighter1);
          font-size: 20px;
          margin: 0 !important;
        }
      }

      .ant-tabs-tab-active {
        .anticon {
          color: var(--color__dark-blue) !important;
        }
      }

      .ant-tabs-nav-list {
        width: 100%;
      }

      .ant-tabs-content-holder {
        border-bottom: 1px solid var(--border-color-sidebar);
        margin-bottom: 5px;
        padding: 5px 10px 20px;

        .customer-select {
          margin: 5px 0;
        }
      }

      .ant-tabs-tabpane {
        display: flex;
        justify-content: center;
        text-align: center;

        .settings-container {
          display: flex;
          justify-content: space-around;
          width: 100%;
        }
      }
    }
  }

  .ant-layout-sider {
    border-radius: var(--border-radius-base);
    font-family: var(--font-family__sidebar);
    height: calc(100vh - 40px);
    max-width: calc(100% - 1px);
    overflow-y: auto;

    .ant-menu-item-icon {
      align-items: center;
      display: flex;
    }

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > div {
        max-width: calc(100% - 1px);
      }

      .ant-menu-inline,
      .ant-menu-inline-collapsed {
        border: 0;
        padding-top: 7px;

        .ant-menu-item,
        .ant-menu-submenu-title {
          margin-bottom: 0;
          padding: 0 14px !important;
          position: relative;
        }

        .ant-menu-item,
        .ant-menu-submenu {
          &:first-child {
            margin-top: 0;
          }
        }

        .ant-menu-item-selected {
          background-color: unset;
        }

        .ant-menu-item-selected,
        .ant-menu-item-active {
          .anticon path {
            fill: var(--color__dark-blue);
          }
        }

        .ant-menu-submenu-active {
          .ant-menu-submenu-title > span > .menu-item,
          .ant-menu-submenu-title > .anticon {
            color: var(--color__dark-blue);
          }
        }

        .ant-menu-submenu .ant-menu-item-active .menu-item {
          z-index: 3;
        }

        .ant-menu-item-active,
        .ant-menu-item-selected {
          &::before {
            background-color: var(--color__dark-blue);
            border-radius: 10px;
            bottom: 0;
            content: '';
            left: 14px;
            opacity: 0.2;
            position: absolute;
            right: 14px;
            top: 0;
          }

          > span {
            z-index: 2;
          }

          a {
            color: var(--color__dark-blue);
          }

          &::after {
            background-color: var(--color__dark-blue);
            border-radius: 10px 0 0 10px;
            border-right-color: var(--color__dark-blue);
            right: 1px;
            width: 4px;
          }

          .sidebar-link-container {
            border-radius: 7px;
          }
        }

        .ant-menu-submenu-inline {
          .ant-menu-sub {
            .ant-menu-item-active,
            .ant-menu-item-selected {
              &::after {
                background-color: var(--color__blue);
                border-radius: var(--border-radius__md);
                left: -3px;
                width: 8px;
              }
            }
          }
        }

        .ant-menu-item a {
          align-items: center;
          display: flex;
          height: 50px;
        }

        .ant-menu-item,
        .ant-menu-submenu-title {
          align-items: center;
          display: flex;
          font-size: var(--font-size__standard);
          height: 50px;

          .anticon {
            font-size: 19px;
            margin: 0 16px;
            z-index: 1;
          }
        }

        .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-item-active):not(.ant-menu-item-only-child) {
          color: var(--color__gray_lighter1);

          .sidebar-link {
            color: var(--color__gray_lighter1);
          }
        }

        .ant-menu-submenu:not(.ant-menu-submenu-selected):not(.ant-menu-submenu-active) {
          color: var(--color__gray_lighter1);

          .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-item-active) a {
            color: var(--color__gray_lighter1);
          }
        }

        .ant-menu-sub {
          .ant-menu-item {
            height: 50px;
            margin-top: 11px;
            padding-left: 16px;

            a {
              align-items: center;
              display: flex;
              font-size: var(--font-size__standard);
              letter-spacing: 0.34px;

              span {
                transition: unset;
                transition: unset;
              }
            }
          }
        }
      }

      .ant-menu-inline-collapsed {
        .ant-menu-item {
          > span {
            width: 0;
          }
        }

        .ant-menu-item-active,
        .ant-menu-item-selected {
          &::before {
            left: 16px;
            right: 16px;
          }
        }
      }

      .collapsed-menu {
        padding-left: 0;
      }
    }
  }

  .ant-layout-sider-collapsed {
    .ant-menu-inline-collapsed {
      border: 0;
      padding-top: 7px;

      .ant-menu-item,
      .ant-menu-submenu {
        align-items: center;
        display: flex;
        font-size: var(--font-size__standard);
        height: 50px;
        letter-spacing: 0.34px;
        margin-bottom: 0;
        margin-top: 21px;
      }

      .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-item-active),
      .ant-menu-submenu:not(.ant-menu-submenu-selected):not(.ant-menu-submenu-active) {
        color: var(--color__gray_lighter1);
      }

      .ant-menu-item,
      .ant-menu-submenu {
        &:first-child {
          margin-top: 0;
        }
      }

      .ant-menu-submenu-title {
        margin-top: 0;
      }

      .ant-menu-submenu-title {
        margin-bottom: 0;
      }
    }
  }

  .sidebar-logo {
    align-items: center;
    display: flex;
    height: 107px;
    justify-content: center;
    margin-bottom: 10px;

    @include media(lg) {
      margin-bottom: 60px;
    }

    h1 {
      color: #fff;
      font-size: 0.8em;
    }
  }

  .sidebar-logo-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 15px 18px 0;

    @include media(lg) {
      padding: 35px 18px 0;
    }

    &,
    &--collapsed {
      img {
        height: 56px;
        margin-top: 16px;
        max-width: 100%;
      }
    }
  }

  .sidebar-logo-content--collapsed {
    width: 58px;

    img {
      height: 50px;
    }
  }

  .sidebar-bottom-container {
    font-size: var(--font-size__standard);
    padding: 0 24px 20px 22px;

    &__links {
      margin: 20px 0;

      .contact-button,
      .contact-button--collapsed {
        align-items: center;
        color: var(--color__dark-blue);
        display: flex;
        transition: all 0.2s;
        transition: all 0.2s;

        &:hover {
          opacity: 0.7;
        }

        .anticon {
          margin-right: 16px;
        }

        + a {
          margin-top: 10px;
        }
      }

      .contact-button--collapsed {
        .anticon {
          margin: 0;
        }

        + a {
          margin-top: 20px;
        }
      }
    }

    .legal-info {
      font-size: 14px;

      &__pages {
        list-style: none;

        a {
          color: inherit;

          &:hover {
            color: var(--color__dark-blue);
          }
        }
      }
    }
  }

  &.sidebar-collapsed {
    .ant-menu-item-selected,
    .ant-menu-item,
    .ant-menu-item-active {
      transition: none;
    }

    .ant-menu-item {
      > span:last-child {
        max-width: none;
        opacity: 1;

        .sidebar-text {
          display: none;
        }
      }
    }

    .ant-menu-vertical .ant-menu-item-selected::after {
      background-color: var(--color__dark-blue);
      border-radius: 10px 0 0 10px;
      opacity: 1;
      right: 0;
      transform: none;
      width: 4px;
    }

    .ant-menu-item,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: transparent;
      display: flex;
      justify-content: center;
      padding: 0 !important;
    }

    .anticon + span {
      width: 0;
    }

    .sidebar-bottom-container {
      display: flex;
      justify-content: center;
      padding: 0 20px;
      width: 100%;
    }
  }
}

.ant-menu-item-only-child:not(.ant-menu-item-selected):not(.ant-menu-item-active) a {
  color: var(--color__gray_lighter1);
}

.ant-menu-item-only-child,
.ant-menu-item-active,
.ant-menu-item-selected a {
  span {
    transition: unset !important;
    transition: unset !important;
  }
}
