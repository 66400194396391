@mixin statusMessage($color, $back) {
  .ant-select:not(.ant-select-customize-input) > .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  > .ant-select-selector {
    background-color: $back;
    border-color: $back;
    color: $color;

    .ant-select-selection-item {
      color: $color;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  > .ant-select-selector {
    box-shadow: 0 0 0 2px rgba($color, 0.5);
  }

  .ant-select-selection-item .anticon,
  .ant-select-arrow {
    color: $color;
  }
}

.message-status {
  svg {
    margin-right: 10px;
  }

  .ant-select:not(.ant-select-customize-input) {
    min-width: 190px;

    > .ant-select-selector {
      align-items: center;
      border-radius: var(--border-radius-base);
      color: #afafaf;
      display: flex;
      height: 50px;
      line-height: 50px;

      .ant-select-selection-item {
        align-items: center;
        display: flex;
      }
    }
  }

  &.OPEN-status {
    @include statusMessage(var(--color__dark-blue), #d3eafe);
  }

  &.URGENT-status {
    @include statusMessage(#f50026, #fee6e9);
  }

  &.CALLBACK-status {
    @include statusMessage(#e09c00, #f9ebcc);

    .custom-icon {
      margin-top: -4px;
    }
  }

  &.IMPORTANT-status,
  &.HIGH-status {
    @include statusMessage(#f07019, #fce2d1);
  }
}
