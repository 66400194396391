@import '../../../styles/mixins';

.user-dropdown {
  align-items: center;
  border: 1px solid var(--border-color-sidebar);
  border-radius: var(--border-radius-base) !important;
  color: var(--color__gray-dark);
  display: flex;
  font-size: 16px;
  height: 50px;

  &.ant-dropdown-open {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  span.anticon {
    color: #bfbfbf;
    font-size: 15px;
    margin-left: 32px;
  }
}
