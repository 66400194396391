@import '../../styles/mixins';

.rate-section {
  flex: 1 1;
  font-size: 16px;
  max-width: 400px;
  padding: 23px 20px;

  .txt-bold {
    font-weight: 700;
  }

  > .rate-section__time {
    display: flex;

    > .icon-block {
      > .icon-block__title {
        color: #ef9c00;
        font-size: 14px;

        @include media(lg) {
          font-size: 16px;
        }
      }
    }

    > .rate-section__time-hours {
      color: var(--color__blue);
      font-weight: 300;
      margin-left: 10px;
    }
  }

  > .rate-section__subtitle {
    color: var(--color__gray_light);
    margin-left: 30px;
  }

  &--night {
    > .rate-section__time {
      > .icon-block {
        > .icon-block__title {
          color: #a270f0;
        }
      }
    }
  }

  &--total {
    align-items: flex-start;
    display: flex;

    @include media(lg) {
      justify-content: flex-end;
    }


    .info {
      cursor: pointer;
      line-height: 0;
      margin-left: auto;
      margin-top: 3px;

      @include media(lg) {
        margin-left: 60px;
      }
    }

    > .rate-section__title {
      color: var(--color__gray_light);

      > .rate-section__title-key {
        color: #707070;
        font-size: 14px;
        margin-right: 15px;

        @include media(lg) {
          font-size: 16px;
        }
      }
    }
  }
}
