@import '../../styles/mixins';

.ant-table {
  @include responsive-table;
  background-color: transparent;
}

.ant-table-empty tr > td {
  background-color: #fff;

  .ant-empty-description {
    color: var(--color__gray-dark);
  }
}

.main-table {
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  tr {
    @include media(lg) {
      &:last-child td:first-child {
        border-bottom-left-radius: var(--border-radius-base);
      }

      &:last-child td:last-child {
        border-bottom-right-radius: var(--border-radius-base);
      }
    }
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-sorter-inner {
    color: var(--color__black-light);
  }

  .ant-table-column-title {
    color: var(--color__semi-black);
    flex: 0 0;
    margin-right: 10px;
  }

  .tarif-sun {
    color: var(--color__sun);
  }

  .tarif-moon {
    color: var(--color__moon);
  }

  &.ant-table-wrapper {
    border: 0;
    border-radius: var(--border-radius-base);

    @include media(lg) {
      margin: 20px 25px 20px 0;
    }

    .ant-table-thead > tr > th {
      background-color: var(--color__semi-white);
      color: var(--color__gray_light);
      font-size: var(--font-size__standard);
      font-weight: 700;
      padding: 9.7px 20px;

      &:first-child {
        border-top-left-radius: var(--border-radius-base);
      }

      &:last-child {
        border-top-right-radius: var(--border-radius-base);
      }

      .ant-table-column-sorters {
        padding: 0;

        .ant-table-column-sorter-full {
          margin-top: unset;
        }

        .ant-table-column-sorter {
          color: #d5d5d5;
          display: flex;

          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: var(--color__blue);
          }
        }
      }
    }

    .ant-table-tbody {
      color: var(--color__semi-black);
      font-size: var(--font-size__standard);

      .ant-table-row {
        height: 55.33px;

        > td {
          transition: none;
        }

        .ant-table-cell {
          background-color: var(--color__white);
          border-bottom: 1px solid var(--color__semi-white);
          max-width: calc(100vw - 30px);

          @include media(lg) {
            max-width: unset;
          }
        }
      }
    }

    .ant-table-tbody > tr > td {
      padding: 14.7px 20px;
    }
  }

  .ant-table-pagination {
    justify-content: center;

    li {
      font-size: var(--font-size__standard);
      height: 50px;
      line-height: 50px;
      width: 50px;

      &,
      button {
        border: 0;
        border-radius: var(--border-radius-base);
      }

      &:hover button,
      &:hover a,
      &.ant-pagination-item-active a {
        color: var(--color__dark-blue);
      }

      &.ant-pagination-item-active a {
        font-weight: bold;
      }
    }

    li.ant-pagination-options {
      display: flex;
      width: auto;

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0;
        border-radius: var(--border-radius-base);
        height: 50px;
      }
    }

    .ant-pagination-simple-pager {
      display: inline-flex;
      width: unset;
    }
  }
}
