.file-list-item {
  align-items: center;
  background-color: rgb(246, 251, 255);
  border-radius: 4px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;

  .file-info {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .anticon {
      margin-right: 8px;
    }
  }
}
