.user-avatar {
  background-color: var(--color__dark-blue);
  border-radius: 50%;
  color: var(--color__white);
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin-right: 15px;
  width: 30px;
}
