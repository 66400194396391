@import '../../styles/mixins';

.add-or-edit-modal {
  &.ant-modal {
    min-width: 80vw;
    top: 0;

    @include media(lg) {
      top: 100px;
    }

    .ant-modal-body {
      max-height: 65vh;
      overflow-y: auto;
    }

    .name {
      display: flex;
    }

    .separator {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .user-modal-row {
        &.ant-row {
          padding-top: 15px;
        }
      }
    }

    .modal-section-title {
      color: var(--color__gray);
      font: var(--font__content-title);

      margin-top: 20px;
    }

    .ant-col-4 {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    .ant-form-item {
      display: block;

      label {
        color: var(--color__gray_light);
        font: var(--font__content-standard);

        &::after {
          content: '';
        }
      }
    }

    .ant-input {
      background-color: var(--color__white);
      border: var(--border__table);
      border-radius: var(--border-radius__sm);
      color: var(--color__gray_light);
      font: var(--font__content-tabs);
      padding: 10.34px 15px;

      &::placeholder {
        color: var(--color__gray_light);
        font: var(--font__content-tabs);
        opacity: 0.7;
      }
    }

    .ant-select {
      .ant-select-selector {
        align-items: center;
        background-color: var(--color__white);
        border: var(--border__table);
        border-radius: var(--border-radius__sm);
        display: flex;
        min-height: 44px;

        .ant-select-selection-item,
        .ant-select-item {
          color: var(--color__gray_light);
          font: var(--font__content-tabs);
        }
      }
    }
  }
}
