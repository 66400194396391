@import '../../styles/mixins';

.empty-container {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 50vh;
  justify-content: center;

  @include media(lg) {
    margin: 20px;
  };
}
