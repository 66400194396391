@import 'mixins';

.ant-modal {
  padding: 0;
  top: 0;

  @include media(lg) {
    top: 100px;
  }
}
