.ant-drawer-body {
  height: 100vh;
  overflow: auto;
  padding-left: 0;
  padding-right: 0;

  .drawer-sidebar-container {
    height: 100%;
    width: 100%;

    .sidebar-container {
      width: 100%;

      .sidebar-content {
        height: 100%;
        margin: 0;
        width: 100%;

        .ant-layout-sider {
          border-radius: unset !important;
          height: 100%;
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }
  }
}
