#custom-expand-icon {
  &.custom-expand-icon {
    font-size: 16px;
    margin: 0;
    position: absolute;
    right: 50px;

    transform: rotate(0deg);
    transition: transform 0.1s linear;

    &__open {
      transform: rotate(90deg);
    }

    .sidebar-collapsed & {
      right: 10px;
    }
  }
}
