.online-button,
.offline-button {
  align-items: center;
  border-radius: var(--border-radius-base) !important;
  display: flex;
  font: var(--font__content-standard);
  height: 50px;
  line-height: 50px;
}

.offline-button {
  background-color: var(--color__red_light) !important;
  border: 1px solid var(--color__red_active) !important;
  color: var(--color__red_active) !important;

  path {
    fill: var(--color__red_active);
  }
}

.online-button {
  background-color: var(--background-color__green_active) !important;
  border: var(--border__active-button) !important;
  color: var(--color__green_active) !important;
}
