.attachments {
  height: 600px;
  overflow-y: auto;
  padding: 25px;

  > .ant-spin {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  > .attachment {
    align-items: center;
    background-color: var(--background-color__gray_light);
    border-radius: 3px;
    box-shadow: 3px 1px 7px -1px rgba(34, 60, 80, 0.2);
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 15px;

    > .attachment-meta {
      align-items: center;
      display: flex;
      max-width: 85%;

      .attachment-meta__title {
        font-weight: 600;
        margin-left: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .anticon {
        > svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    > .attachment-actions {
      display: flex;

      > .attachment-actions__button {
        background-color: transparent;
        border: 0;
        outline: none;

        &:not(:last-child) {
          margin-right: 20px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.ant-collapse {
  &-item {
    iframe {
      width: 100%;
      min-height: 450px;
      border: none;
    }

    video {
      width: 100%;
      height: 100%;
      max-height: 400px;
    }

    .image-preview-container {
      height: 100%;
      max-height: 400px;
      overflow-y: auto;

      .image-preview {
        max-width: 100%;
        height: auto;
      }
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;

      &-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-collapse-extra {
        display: flex;
        margin-left: auto;
      }
    }

    .ant-collapse-content-box {
      text-align: center;
    }
  }
}
