.custom-button {
  &.ant-btn {
    align-items: center;
    background-color: var(--color__blue);
    border: unset;
    border-radius: var(--border-radius__button);
    color: var(--color__white);
    display: flex;
    font: var(--font__content-standard);
    justify-content: center;
    min-width: 131px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.blue {
  &.ant-btn {
    background-color: var(--color__blue);
    color: var(--color__white);
  }
}

.light-blue {
  &.ant-btn {
    background-color: var(--background-color__blue_light);
    color: var(--color__blue);
  }
}

.gray {
  &.ant-btn {
    background-color: var(--background-color__gray_light3);
    color: var(--color__gray_lighter2);
  }
}

.white {
  &.ant-btn {
    background-color: var(--color__white);
    border: 1px solid #979797;
    color: var(--color__gray_lighter1);
  }
}

.green {
  &.ant-btn {
    background-color: var(--color__green_active);
    color: var(--color__white);
  }
}

.beige {
  &.ant-btn {
    background-color: rgba(254, 156, 0, 0.2);
    color: #e09c00;

    &:hover {
      color: #e09c00;
    }

    .anticon-phone {
      svg {
        color: #e09c00 !important;
      }
    }
  }
}
