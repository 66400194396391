:root {
  --color__gray: #707070;
  --color__gray-dark: #7d8592;
  --color__gray_light: rgba(112, 112, 112, 0.8); // color: #707070;  opacity: 0.8;
  --color__gray_lighter1: #8d8d8d;
  --color__gray_lighter2: #8b8b8b;
  --color__gray_lighter3: #8c8c8c;
  --color__gray_lighter4: #f5f6fa;
  --color__blue: #2297f8;
  --color__dark-blue: #007da4;
  --color__blue_light: rgba(34, 151, 248, 0.8);
  --color__white: #fff;
  --color__semi-white: #e6edf5;
  --color__input-background: #f2f6fa;
  --color__green_active: #00c30f;
  --color__green_active-transparent: rgba(0, 195, 15, 0.2);
  --color__red_active: #f50026;
  --color__red_light: #f5002634;
  --color__black-light: #272d3c;
  --color__semi-black: #0a1629;
  --color__semi-black-transparent: #0a162982;
  --color__badge: #afafaf;
  --color__badge-urgent: #f50026;
  --color__badge-callback: #e09c00;
  --color__badge-high: #f07019;
  --color__sun: #f2b033;
  --color__moon: #a270f0;

  --background-color__badge: #efefef;
  --background-color__badge-open: #d3eafe;
  --background-color__badge-urgent: #fee6e9;
  --background-color__badge-callback: #f9ebcc;
  --background-color__badge-high: #fce2d1;
  --background-color__gray_light: #f7f7f7;
  --background-color__gray_light2: #f8f8f8;
  --background-color__blue_light: #ddeffe;
  --background-color__gray_light3: #f5f5f5;
  --background-color__button-background: #c6d4e4;
  --background-color__green_active: #00c30f34;
  --background-color__skeleton: #d1d1d1;
  --border-color-sidebar: #f0f2f5;

  --font-family__table: 'Nunito Sans', sans-serif;
  --font-family__sidebar: 'Navigo', sans-serif;
  --font-family__Navigo: 'Navigo', sans-serif;

  --font-size__standard: 16px;
  --font-size__title: 36px;
  --font-size__sub-title: 18px;
  --font-size__app-title: 27px;

  --font__content-standard: 600 16px/22px var(--font-family__table);
  --font__page-title: 600 24px/34px var(--font-family__sidebar);
  --font__content-title: bold 16px/22px var(--font-family__table);
  --font__content-tabs: normal 16px/22px var(--font-family__table);
  --font__blue-title: 500 16px/22px var(--font-family__sidebar);
  --font__small: 300 13px/19px var(--font-family__sidebar);
  --font__small2: 300 14px/19px var(--font-family__table);
  --font__small-bold: bold 14px/19px var(--font-family__table);
  --font__inbox-preview-title: 500 22px/29px var(--font-family__sidebar);
  --font__content-inbox-title: 600 24px/24px var(--font-family__sidebar);

  --border-radius-base: 16px;
  --border-radius__xsm: 3px;
  --border-radius__sm: 5px;
  --border-radius__md: 8px;
  --border-radius__lg: 10px;
  --border-radius__button: 6px;

  --border__table: 1px solid #e3e3e3;
  --border__page-header: 1px solid #f5f5f5;
  --border__main-header: 0.5px solid #d5d5d5;
  --border__active-button: 1px solid #00c30f;
  --border__notifications: 1px solid #f6e1b3;

  --base-background: #f1f6fb;
}
