@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,800,800i,900i&display=swap');

*,
*::before,
::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  scrollbar-color: var(--color__dark-blue) var(--background-color__blue_light);
  scrollbar-width: thin;
}

html {
  scrollbar-color: var(--color__dark-blue) var(--color__semi-white);
  scrollbar-width: thin;
}

.ant-layout {
  background-color: transparent;
}

body {
  background: var(--border-color-sidebar);
  background-color: var(--base-background);
  font-family: var(--font-family__Navigo);
  height: auto;
  letter-spacing: 0.34px;
}

.tag-icon {
  &.red path {
    fill: var(--color__red_active);
  }
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--background-color__blue_light);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color__dark-blue);
  border-radius: 5px;
}
