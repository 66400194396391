@import '../styles/mixins';

.ant-layout {
  &.main-layout {
    overflow-x: hidden;
    padding-left: 303px;
    transition: padding 0.2s;

    &.sidebar-collapsed {
      padding-left: 120px;
    }
  }

  &.site-layout {
    margin-left: 5px;
    margin-right: 5px;
    overflow-x: auto;
    width: unset !important;

    @include media(lg) {
      margin-right: 0;
    }

    .ant-layout-content {
      min-height: 280px;
      padding: 0;
    }
  }

  &.is-mobile {
    padding: 0 10px;

    header {
      height: 70px;
      left: 0;
      padding: 0 10px !important;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 2;
    }

    main {
      margin-top: 70px;
    }
  }

  header {
    height: 100px;
    padding: 0;
  }
}
