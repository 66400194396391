@import '../../styles/mixins';

.page-header {
  align-items: center;
  border: var(--border__page-header);
  border-radius: 5px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media(lg) {
    padding: 20px 20px 32px;
  }

  &__title {
    color: var(--color__black-light);
    font-size: var(--font-size__app-title);

    font-weight: bold;
    letter-spacing: 0.51px;

    @include media(lg) {
      font-size: var(--font-size__title);
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    @include media(lg) {
      align-items: center;
      flex-direction: row;
      width: unset;
    }

    .ant-tabs {
      margin: 0;
      width: 100%;

      @include media(lg) {
        margin: initial;
      }

      .ant-tabs-nav-list {
        width: 100%;
      }
    }

    .ant-select {
      margin: unset !important;
      width: unset;
    }

    .reports-actions {
      .anticon {
        margin-left: unset;

        @include media(lg) {
          margin-left: 15px;
        }

        + .anticon {
          margin-left: 15px;
        }
      }
    }
  }
}

.instructions .action-icons {
  margin-right: 25px;
}
