.custom-badge {

  background-color: var(--color__dark-blue);
  border-radius: 8px;
  color: var(--color__white);
  font-size: 14px;
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 24px;

  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;

  .sidebar-collapsed & {
    right: 15px;
    top: 0;
    transform: translateY(15%);
    z-index: 2;
  }

  [class*='tooltip-content'] & {
    display: none;
  }
}
