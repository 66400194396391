@import 'mixins';

.filter-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          padding-left: 10px;
          padding-right: 10px;

          @include media(lg) {
            padding: 24px;
          }
        }
      }
    }
  }
}
