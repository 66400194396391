@import 'mixins';

.ant-popover {
  padding: 10px;

  .ant-popover-buttons {
    display: flex;
    justify-content: space-between;

    @include media(lg) {
      display: block;
    }

    .ant-btn {
      height: 32px;

      @include media(lg) {
        font-size: inherit;
        height: inherit;
      }
    }
  }
}
