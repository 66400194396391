.ant-modal {
  .ant-modal-content {
    border: var(--border__table);
    border-radius: var(--border-radius__sm);

    .ant-modal-close {
      padding: 4px 0;

      &:focus {
        outline: unset;
      }
    }

    .ant-modal-header {
      background-color: var(--background-color__gray_light3);
      border-radius: 5px 5px 0 0;
      padding: 20px;

      .ant-modal-title {
        color: var(--color__gray);
        font: var(--font__page-title);
      }
    }

    .ant-modal-body {
      color: var(-color__gray_light);
      font: var(--font__content-tabs);
      padding: 20px;
    }

    .ant-modal-footer {
      border-radius: 0 0 5px 5px;
      border-top: unset;
      display: flex;
      justify-content: flex-end;
      padding: 15px 20px 20px;

      .ant-btn {
        margin-left: 20px;
      }
    }
  }
}
