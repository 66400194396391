@import 'mixins';

.ant-picker-dropdown-range {
  height: 280px;
  overflow-y: auto;
  position: fixed;

  @include media(lg) {
    height: unset;
    overflow-y: unset;
  }

  .ant-picker-range-wrapper {
    @include media(lg) {
      margin-left: -13px;
    }
  }

  .ant-picker-panels {
    flex-direction: column;
  }
}
