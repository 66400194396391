@import '../../styles/mixins';

.info-section-container {
  background-color: var(--color__white);
  border: var(--border__page-header);
  border-radius: 0 0 0 5px;
  border-radius: var(--border-radius-base);
  color: var(--color__semi-black);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 5px;
  min-height: 69px;
  width: 100%;

  @include media(lg) {
    flex-direction: unset;
    margin-top: unset;
    width: calc(100% - 25px);
  }
}
