.clickable-phone-number {
  align-items: center;
  color: var(--color__dark-blue);
  display: inline-flex;
  font-weight: bold;

  .anticon-phone {
    color: #00800f;
  }
}
