.main-select-dropdown {
  width: auto !important;
}

.ant-layout-header.main-header {
  align-items: center;
  background: transparent;
  display: flex;
  justify-content: space-between;

  .main-header-left-container {
    align-items: center;
    display: flex;
    width: 100%;

    .main-search {
      width: 245px;

      .ant-input,
      .ant-btn {
        background-color: var(--color__white);
        border: var(--border__main-header);
      }

      .ant-input {
        height: 40px;
      }

      .ant-input-wrapper {
        .ant-input-group-addon {
          .ant-btn {
            align-items: center;
            border-left: 0;
            display: flex;
            justify-content: center;

            &:hover {
              z-index: 0;
            }
          }
        }
      }
    }

    .customer-select {
      max-width: 178px;
    }

    .trigger {
      align-items: center;
      background-color: var(--color__white);
      border-radius: var(--border-radius-base);
      cursor: pointer;
      display: flex;
      font-size: 18px;
      height: 50px;
      justify-content: center;
      line-height: 64px;
      line-height: 64px;
      margin-right: 44px;
      transition: color 0.3s;
      width: 50px;

      rect {
        stroke: #707070;
        transition: 0.3s ease-in-out;
      }

      &:hover rect,
      &.active rect {
        stroke: var(--color__dark-blue);
      }
    }
  }

  .main-header-right-container {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 40px;
    justify-content: space-around;
    margin: 0 25px;

    span.ant-select-selection-item {
      padding-right: 24px;
    }

    .offline-button {
      background-color: var(--color__red_light);
      border: 1px solid var(--color__red_active);
      color: var(--color__red_active);

      path {
        fill: var(--color__red_active);
      }
    }

    .user-divider {
      background-color: rgba(112, 112, 112, 0.2);
      height: 40px;
      margin-left: 20px;
      margin-right: 29px;
    }

    img {
      height: 35px;
    }
  }
}
