@import '../../styles/mixins';

.action-icons {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  @include media(lg) {
    width: unset;
  }

  > span {
    background-color: var(--color__white);
    border-radius: var(--border-radius-base);
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    position: relative;
    width: 50px;

    + span {
      margin-left: 15px;
    }

    .anticon {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
    }

    &:hover path {
      fill: var(--color__dark-blue);
    }

    &.active {
      background-color: var(--background-color__badge-open);

      &::after {
        background-color: var(--color__red_active);
        border-radius: 100px;
        color: var(--color__white);
        content: '!';
        font-size: 14px;
        font-weight: bold;
        height: 18px;
        line-height: 18px;
        position: absolute;
        right: 0;
        text-align: center;
        top: -5px;
        width: 18px;
        z-index: 2;
      }
    }
  }

  svg {
    color: var(--color__gray-dark);
    cursor: pointer;
    height: 18px;
    margin-left: 12px;
    margin-right: 12px;
    width: 18px;
  }
}
