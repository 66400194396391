@import '../../styles/mixins';

.search-select-container {
  display: flex;
  position: relative;

  @include media(lg) {
    border: 1px solid var(--border-color-sidebar) !important;
  }

  .search-button {
    color: var(--color__gray-dark);
    display: none;

    @include media(lg) {
      align-items: center;
      background-color: var(--color__white);
      border-radius: var(--border-radius-base);
      display: flex;
      height: 50px;
      justify-content: center;
      width: 50px;

      :hover {
        cursor: pointer;
      }
    }

    &.active {
      box-shadow: 9px 10px 24px -17px rgb(66, 68, 90);
    }
  }

  .select-and-search {
    border-bottom-right-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
    display: flex;
    width: 100%;

    @include media(lg) {
      box-shadow: 9px 10px 24px -17px rgb(66, 68, 90);
      left: 35px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.5s, opacity 0.5s;
      width: 700px;
      z-index: 2;

      &.active {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }

  .search-select {
    font-family: var(--font-family__Navigo);
    margin-right: 0 !important;
    max-width: 150px;
    min-width: 130px !important;

    .ant-select-selector {
      border: 1px solid var(--border-color-sidebar) !important;
      border-bottom-left-radius: var(--border-radius-base);
      border-top-left-radius: var(--border-radius-base);
      color: var(--color__gray-dark) !important;
      height: 50px !important;

      @include media(lg) {
        border: unset !important;
        border-bottom-left-radius: unset;
        border-top-left-radius: unset;
      }

      .ant-select-selection-search {
        line-height: 50px !important;
      }

      .ant-select-selection-item {
        line-height: 50px !important;
      }
    }

    &.ant-select-open .ant-select-selector {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      box-shadow: none;
    }
  }

  .search-input {
    font-family: var(--font-family__Navigo) !important;
    margin-right: 2px !important;
    width: 100% !important;

    .ant-input {
      border: 1px solid var(--border-color-sidebar) !important;
      border-left: unset;
      border-right: unset;
      color: var(--color__gray-dark) !important;
      height: 50px !important;
      line-height: 60px;
      overflow: visible;

      @include media(lg) {
        border: unset !important;
      }

      &:focus {
        height: 46px !important;
        margin-top: 2px;
        outline: none !important;
      }

      &::placeholder {
        line-height: 60px;
        overflow: visible;
      }
    }

    .ant-input-group-addon {
      border: 0;
      border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0 !important;
      left: -1px;
      padding: 0;

      .ant-btn {
        align-items: center;
        border: 1px solid var(--border-color-sidebar) !important;
        border-left: unset !important;
        border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0 !important;
        display: flex;
        height: 50px;
        justify-content: center;

        @include media(lg) {
          border: unset !important;
        }
      }
    }
  }
}
