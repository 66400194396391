.input-with-button {
  display: flex;
  justify-content: left;
  width: 100%;

  .ant-select {
    width: 100%;

    &-selector {
      border-radius: 5px !important;
      min-height: 2.5rem !important;
    }
  }

  .ant-btn {
    background: #efefef;
    border: 1px solid #2297f8;
    border-radius: 5px;
    font-weight: 600;
    margin-left: -0.5rem;
    min-height: 2.5rem;
    width: 66%;

    &:not(:hover) {
      border: 0;
      color: #707070;
    }
  }
}
