@import '../../styles/mixins';

.message-answer {
  color: #707070;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @include media(lg) {
    min-width: 560px;
  }

  label {
    color: #7d8592;
    font-size: 16px;
  }

  &__message {
    flex-flow: column;

    label {
      margin-bottom: 10px;
    }

    textarea.ant-input {
      background-color: #f2f6fa;
      border: 1px solid #e6edf5;
      border-radius: var(--border-radius-base);
      margin-bottom: 20px;
      min-height: 100px;
      resize: none;
    }
  }

  &__attachment {
    cursor: pointer;
    margin-bottom: 30px;

    .ant-upload {
      .anticon {
        color: var(--color__dark-blue);
        margin-right: 13px;
      }

      > * {
        font-size: var(--font-size__standard);
        vertical-align: text-top;
      }

      .attachment-text {
        color: var(--color__dark-blue);
        text-decoration: underline;
      }
    }

    .ant-upload-list {
      margin-top: 10px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    @include media(lg) {
      column-gap: 5%;
      display: grid;
      grid-template-columns: 47% 47%;
      grid-template-rows: auto auto;
      margin-top: 1.5rem;
      max-width: 100%;
    }

    .input-with-button {
      background-color: #ecf1f7;
      border-radius: var(--border-radius-base);

      .ant-select-selection-placeholder {
        padding-left: 5px;
      }

      div.ant-select-selector {
        background-color: #ecf1f7;
        border: 1px solid #e6edf5;
        border-radius: var(--border-radius-base) 0 0 var(--border-radius-base) !important;
      }

      .ant-select-focused:not(.ant-select-disabled) div.ant-select-selector,
      .ant-select:not(.ant-select-disabled):hover div.ant-select-selector {
        border-color: transparent;
        box-shadow: none;
      }
    }

    div[class^='message-answer__actions-'] .ant-btn,
    div.input-with-button .ant-btn {
      background-color: #a0b7d180;
      border: 1px solid transparent;
      border-radius: var(--border-radius-base);
      color: #0a1629;
      font-size: var(--font-size__standard);
      font-weight: normal;
      line-height: 34px;
    }

    div[class='message-answer__actions-save'] .ant-btn {
      background-color: var(--color__dark-blue);
      color: var(--color__white);
      margin-top: 10px;

      @include media(lg) {
        margin-top: 0;
      }
    }

    &-send__email {
      grid-column: 1;
      grid-row: 1;
      margin-bottom: 20px;
    }

    &-send__sms {
      grid-column: 2;
      grid-row: 1;
      margin-bottom: 20px;
    }

    &-send__memotech {
      grid-column: 1;
      grid-row: 2;
    }

    &-save {
      grid-column: 2;
      grid-row: 2;

      .ant-btn {
        background: #2297f8;
        border: 0;
        border-radius: 5px;
        color: #fff;
        font-weight: 600;
        min-height: 2.5rem;
        width: 100%;

        &:hover {
          background: #fff;
          border: 1px solid #2297f8;
          color: #2297f8;
        }
      }
    }

    &-save__attachment,
    &-send__memotech {
      .ant-btn {
        background: #efefef;
        border: 1px solid #2297f8;
        border-radius: 5px;
        font-weight: 600;
        min-height: 2.5rem;
        width: 100%;

        &:not(:hover) {
          border: 0;
          color: #707070;
        }
      }
    }
  }

  .new-message {
    .ant-select-selector {
      align-items: center;
      border-radius: 5px !important;
      display: flex;
      min-height: 2.5rem !important;

      .ant-select-selection-item {
        align-items: center;
        display: flex;
      }
    }

    .form-loader {
      display: flex;
      justify-content: center;
      padding: 5rem;
      width: 100%;
    }

    .new-message__col {
      column-gap: 4%;
      display: grid;
      grid-template-rows: auto auto;
      row-gap: 0;

      &:first-of-type {
        margin-bottom: 10px;
      }

      &:last-of-type {
        margin-bottom: 20px;
      }

      .options {
        align-items: center;
        display: flex;
        justify-content: space-evenly;

        .ant-select-selection-item {
          align-items: center;
          display: flex;

          .anticon {
            margin-right: 5px;
            margin-top: -1px;
          }
        }

        .ant-select,
        label {
          flex: 1 1;
        }

        label {
          margin-left: 1.2rem;
        }
      }
    }
  }

  > .message-content {
    color: #0a1629;
    display: flex;
    font: var(--font__content-tabs);
    justify-content: space-between;
    margin-bottom: 20px;
    max-height: 210px;
    overflow-y: auto;
    width: 100%;

    strong {
      margin-bottom: 10px;
    }

    .message-body__dateTime {
      color: var(--color__gray-dark);
    }

    .messageTable {
      color: #0a1629;
      font-family: var(--font-family__Navigo);
      font-size: var(--font-size__standard);
      margin-top: 10px;

      table tr {
        > td:first-child {
          font-weight: 200;
        }

        > td:last-child {
          font-weight: bold;
        }
      }
    }
  }
}

.ant-select-item-option-content {
  align-items: center;
  display: flex;

  .anticon {
    margin-right: 5px;
    margin-top: -1px;
  }
}
