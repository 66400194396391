.video-notification {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;

  span {
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 15px;
    width: 125px;

    &:not(:last-of-type) {
      margin-right: 15px;
    }

    &:hover {
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    }


    svg {
      margin-bottom: 15px;
      width: 50px;
    }

    &.take-call {
      path {
        fill: #9bf41c;
      }
    }

    &.reject-call {
      path {
        fill: #f95d2d;
      }
    }
  }
}
