.generated-input {
  display: inline-block;

  > .ant-form-item {
    display: block;
    margin-bottom: unset;

    input {
      border-radius: 5px;
      height: 2.5rem;
    }

    .ant-form-item-label {
      > label {
        font-family: 'Nunito Sans';
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}
