@font-face {
  font-family: 'Navigo';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Navigo-Regular-Edited.ttf');
}

@font-face {
  font-family: 'Navigo';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Navigo-Bold-Edited.ttf');
}

@font-face {
  font-family: 'Navigo';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Navigo-Thin-Edited.ttf');
}

@font-face {
  font-family: 'Navigo';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Navigo-Thin-Edited.ttf');
}
