.new-message-modal {
  background-color: #2297f8;

  .ant-modal {
    > .ant-modal-content {
      > .ant-modal-header {
        padding: 15px 30px 7px;
      }
    }
  }
}
