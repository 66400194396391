@mixin tabs {
  .ant-tabs-nav {
    margin: 0;

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      background: var(--color__white);
      font-size: var(--font-size__standard);
      height: 50px;
      line-height: 50px;
      padding: 0 15px;

      .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--color__dark-blue);
      }

      .anticon {
        font-size: 18px;
        margin: 0 12px;

        @include media(lg) {
          margin: 0 5px;
        }
      }

      &:hover {
        color: var(--color__dark-blue);
      }

      > div > span {
        align-items: center;
        display: flex;
      }

      + .ant-tabs-tab {
        margin: 0 0 0 2px;
      }

      &:first-child {
        border-bottom-left-radius: var(--border-radius-base);
        border-top-left-radius: var(--border-radius-base);
      }

      &:nth-last-child(2) {
        border-bottom-right-radius: var(--border-radius-base);
        border-top-right-radius: var(--border-radius-base);
      }
    }
  }
}

@mixin blue-button {
  background-color: var(--background-color__badge-open);
  border: 1px solid var(--color__dark-blue);
  border-radius: var(--border-radius-base);
  color: var(--color__dark-blue);
  font-weight: normal;
  height: 50px;
}

@mixin base-form {
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-control-input-content > .ant-row:not(.ant-row-end) {
    margin-bottom: 21px;
  }

  .ant-row {
    &,
    .ant-col {
      .ant-form-item-label {
        text-align: left;

        > label {
          color: var(--color__gray-dark);
          font-size: var(--font-size__standard);
          height: auto;
          margin-bottom: 5px;
          opacity: 0.8;

          &::after {
            content: none;
          }
        }
      }

      .ant-form-item-control-input-content input,
      .ant-form-item-control-input-content textarea,
      .ant-select-selector {
        background-color: var(--color__input-background);
        border: 0;
        border-radius: var(--border-radius-base);
        font-size: var(--font-size__standard);
        height: 44px;
        line-height: 44px;
        padding: 0 15px;
      }

      .ant-form-item-control-input-content textarea {
        height: auto;
      }

      .ant-select-selector span {
        line-height: 44px;
      }
    }

    .ant-col:not(.ant-form-item-control) .ant-row {
      flex-direction: column;
    }
  }
}

$media: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
  3xl: 1920px,
);

@mixin media($breakpoint) {
  @each $breakpoints, $size in $media {
    @if $breakpoint==$breakpoints {
      @media screen and (min-width: $size) {
        @content;
      }
    }
  }
}

@mixin memo-select {
  .mobile-select {
    margin: 0 5px;
    width: 100%;

    .ant-select-selector {
      border: 1px solid var(--border-color-sidebar) !important;
      border-radius: var(--border-radius-base) !important;
      height: 100% !important;
      min-height: 44px;

      input,
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: var(--color__gray-dark) !important;
        height: 100% !important;
        line-height: 50px !important;
      }

    }

    &.ant-select-open .ant-select-selector {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      box-shadow: none;
    }
  }
}

@mixin responsive-table {
  table {
    border-collapse: separate;
    border-spacing: 0 1em;
    margin: 1em 0;
    min-width: 200px;

    @include media(lg) {
      border-collapse: collapse;
      border-spacing: unset;
    }

    tr {
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
    }

    th {
      display: none;
    }

    td {
      display: block;

      &:first-child {
        padding-top: 0.5em;
      }

      &:last-child {
        padding-bottom: 0.5em;
      }

      ::before {
        color: var(--color__black-light);
        content: attr(data-title)' ';
        pointer-events: none;

        @include media(md) {
          display: none;
        }
      }
    }

    th,
    td {
      text-align: left;

      @include media(sm) {
        display: table-cell;
      }
    }
  }
}
