@import '../../styles/mixins';

.page-header-tabs.ant-tabs {

  @include tabs;
  margin: 0 60px 0 0;
}

.ant-tabs-dropdown {
  .ant-tabs-dropdown-menu-item {
    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}